export default function convertCase(str: string, capitaliseAll = false): string {
  if (str.includes("_")) return snakeToSpace(str, capitaliseAll)
  if (/([a-z])([A-Z])/.test(str)) return camelToSpace(str, capitaliseAll)
  return capitaliseAll
    ? str
        .split(" ")
        .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        .join(" ")
    : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function snakeToSpace(str: string, capitaliseAll: boolean): string {
  const result = str.replace(/_/g, " ")
  return convertCase(result.charAt(0).toUpperCase() + result.slice(1), capitaliseAll)
}

function camelToSpace(str: string, capitaliseAll: boolean): string {
  const result = str.replace(/([a-z0-9])([A-Z])/g, "$1 $2")
  return convertCase(result.charAt(0).toUpperCase() + result.slice(1), capitaliseAll)
}
