import React from "react"

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
      <h1>404 Not Found</h1>
    </div>
  )
}
