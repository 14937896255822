export function formatDate(date?: string, time = true): string {
  if (!date) return "-"

  const config: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  }
  if (time) {
    config.hour = "2-digit"
    config.minute = "2-digit"
  }
  return new Date(date).toLocaleDateString("en-GB", config)
}
