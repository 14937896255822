import { API_KEY, backendEnv } from "../../config"
import NetworkError from "../../models/NetworkError"
import encodeURLParams from "../../utils/encodeURLParams"

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/referral",
  development: "https://access-v2-staging.limbic.ai/referral",
  production: "https://access-v2.limbic.ai/referral"
}

const URL = endpoints[backendEnv] || endpoints.production
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

interface IResponse {
  referrals: any[]
  pagination: {
    total: number
    page: number
    pageSize: number
  }
}

export default async function getReferrals(
  token: string,
  query?: string,
  instanceIDs?: string[],
  page = 1,
  pageSize = 10
): Promise<IResponse> {
  const paramsObject: any = { token, page, pageSize }
  if (Array.isArray(instanceIDs) && instanceIDs?.length) {
    paramsObject.instanceIDs = instanceIDs.join(",")
  }
  if (query?.length) {
    paramsObject.name = query
    paramsObject.signupCode = query
  }
  const params = encodeURLParams(paramsObject)
  const url = `${URL}?${params}`
  const result = await fetch(url, { headers })
  const json = await result.json()
  const { success, data, error, validations } = json
  if (!success && error) throw new NetworkError("", error)
  if (!success && validations) throw new NetworkError("", validations?.join(", "))
  return data
}
