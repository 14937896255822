import invariant from "./utils/invariant"

export const API_KEY = "7bcdcebb51de068a1108d1b66067ff879e7ca6c5056bcf49"
// export const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY as string
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")

export const backendEnv = (process.env.REACT_APP_BACKEND_ENV || "staging") as
  | "staging"
  | "development"
  | "production"
