import React, { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { Direction, TableColumn } from "react-data-table-component"
import getReferrals from "../../../backend/api/getReferrals"
import DataTableBase from "../../components/DataTable/DataTable"
import normaliseFullName from "../../../utils/normaliseFullName"
import { formatDate } from "../../../utils/formatDate"
import ReferralPreview from "../../components/ReferralPreview/ReferralPreview"
import "./ReferralList.css"
import { useTokenContext } from "../../contexts/TokenContext"
import FilterInput from "../../components/FilterInput/FilterInput"

const columns: TableColumn<any>[] = [
  {
    name: "Created At",
    selector: referral => formatDate(referral.created)
  },
  {
    name: "Name",
    selector: referral => normaliseFullName(referral.pii?.nameFirst, referral.pii?.nameLast),
    wrap: true
  },
  {
    name: "Limbic ID",
    selector: referral => referral.signupCode
  },
  {
    name: "Birth Date",
    selector: referral => formatDate(referral.demographic.dob, false),
    grow: 0
  },
  {
    name: "Postcode",
    selector: referral => referral.pii?.addressHome?.postcode ?? "-",
    grow: 0
  },
  {
    name: "Email",
    selector: referral => referral.pii?.email ?? "-"
  },
  {
    name: "Clinic",
    selector: referral => referral.clinic.nameDisplay ?? "-",
    wrap: true
  }
]

export default function ReferralList() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<any[]>([])
  const [query, setQuery] = useState<string | undefined>()
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50, total: 0 })
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  const { token = "" } = useTokenContext()

  // this needs to be debounced
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(
    debounce(
      async (token: string, query?: string, instanceIDs?: string[], page = 1, pageSize = 10) => {
        try {
          setIsLoading(true)
          const result = await getReferrals(token, query, instanceIDs, page, pageSize)
          setData(result.referrals)
          setPagination(result.pagination)
        } catch (e) {
          console.error(e)
        } finally {
          setIsLoading(false)
        }
      },
      300
    ),
    []
  )

  const onChangePage = useCallback(
    (page: number) => {
      const { pageSize } = pagination
      setPagination({ ...pagination, page })
      updateData(token, query, undefined, page, pageSize)
    },
    [pagination, updateData, token, query]
  )

  const onChangeRowsPerPage = useCallback(
    (pageSize: number, page: number) => {
      setPagination({ ...pagination, page, pageSize })
      updateData(token, query, undefined, page, pageSize)
    },
    [pagination, query, token, updateData]
  )

  const onChangeSearch = useCallback(
    (text: string) => {
      const currentQuery = query ?? ""
      const hasCurrentSearch = currentQuery.length >= 3
      setQuery(text)
      if (!text && hasCurrentSearch) {
        setResetPaginationToggle(!resetPaginationToggle)
        updateData(token, undefined, undefined, pagination.page, pagination.pageSize)
      } else if (text.length >= 3) {
        setResetPaginationToggle(!resetPaginationToggle)
        updateData(token, text, undefined, pagination.page, pagination.pageSize)
      }
    },
    [pagination.page, pagination.pageSize, query, resetPaginationToggle, token, updateData]
  )

  useEffect(() => {
    updateData(token, undefined, undefined, pagination.page, pagination.pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="referral-list-container">
      <div className="referral-list-header">
        <FilterInput onChange={onChangeSearch} />
      </div>
      <div className="referral-list-table-container">
        <DataTableBase
          dense
          expandOnRowClicked
          expandableRows
          expandableRowsHideExpander
          fixedHeader
          highlightOnHover
          noHeader
          persistTableHead
          pointerOnHover
          responsive
          striped
          pagination
          paginationServer
          paginationPerPage={pagination.pageSize}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationTotalRows={pagination.total}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationResetDefaultPage={resetPaginationToggle}
          progressPending={isLoading}
          data={data}
          columns={columns}
          direction={Direction.AUTO}
          fixedHeaderScrollHeight="90vh"
          expandableRowsComponent={ReferralPreview}
          keyField={"_id"}
          className="referral-list-table"
        />
      </div>
    </div>
  )
}
