import React from "react"

export default function NoToken() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: 0,
        padding: 0
      }}>
      <h1>Oops!</h1>
      <h3>You need a valid token to proceed</h3>
      <p>Please contact Limbic if you think this is a mistake</p>
    </div>
  )
}
