import "./ReferralPreview.css"
import normaliseFullName from "../../../utils/normaliseFullName"
import { formatDate } from "../../../utils/formatDate"
import convertCase from "../../../utils/convertCase"
import { useMemo } from "react"

function convertQuestionnaire(questionnaire: any) {
  const { responses, total } = questionnaire
  const formattedResponses = responses.map(r => {
    return r.points != null //
      ? `${r.title} - ${r.points} (${r.answer})`
      : `${r.title} - ${r.answer}`
  })
  return `Total: ${total}\n${formattedResponses.join("\n")}\n\n`
}

interface IDetailProps {
  name?: string
  value: any
  convertNameCase?: boolean
  convertValueCase?: boolean
  capitaliseName?: boolean
  capitaliseValue?: boolean
}

function Detail(props: IDetailProps) {
  const {
    name,
    value,
    convertNameCase = true,
    convertValueCase,
    capitaliseName = true,
    capitaliseValue
  } = props

  const formattedValue = useMemo(() => {
    if (typeof value === "string") {
      const lines = (convertValueCase ? convertCase(value, capitaliseValue) : value).split("\n")
      const elements = lines.flatMap((line, index) => [
        <span key={index}>{line}</span>,
        <br key={`${index}_span`} />
      ])
      elements.pop() // Remove the last <br />
      return elements
    }
    if (Array.isArray(value)) {
      if (value.length === 0) return "-"
      return (
        <ul>
          {value.sort().map((v, i) => (
            <Detail
              key={i}
              value={v}
              {...{
                convertNameCase,
                convertValueCase,
                capitaliseName,
                capitaliseValue
              }}
            />
          ))}
        </ul>
      )
    }
    if (typeof value === "object" && value !== null) {
      return (
        <ul>
          {Object.entries(value)
            .sort()
            .map(([k, v], i) => (
              <Detail
                key={i}
                name={k}
                value={v}
                {...{
                  convertNameCase,
                  convertValueCase,
                  capitaliseName,
                  capitaliseValue
                }}
              />
            ))}
        </ul>
      )
    }

    return JSON.stringify(value)
  }, [capitaliseName, capitaliseValue, convertNameCase, convertValueCase, value])
  return (
    <div className="detail">
      {name ? (
        <strong>{convertNameCase ? convertCase(name, capitaliseName) : name}: </strong>
      ) : null}
      {formattedValue}
    </div>
  )
}

export default function ReferralPreview(props: any) {
  const { data: referral } = props
  const name = normaliseFullName(referral.pii?.nameFirst, referral.pii?.nameLast)
  const created = formatDate(referral.created)
  const { questionnaires, ...clinical } = referral.limbic ?? {}
  const formattedQuestionnaires = useMemo(() => {
    if (!questionnaires) return null
    return Object.entries(questionnaires).map(([k, v], i) => (
      <Detail convertNameCase={false} key={i} name={k} value={convertQuestionnaire(v)} />
    ))
  }, [questionnaires])
  return (
    <div className="details">
      <h2>
        {name} ({referral.signupCode})
      </h2>
      <Detail name="Created Date" value={created} />
      <Detail name="Limbic ID" value={referral.signupCode} />
      <Detail name="Postcode" value={referral.pii?.addressHome?.postcode ?? "-"} />

      <h3>Personal Info</h3>
      <Detail value={referral.pii ?? {}} />

      <h3>Demographics</h3>
      <Detail convertValueCase capitaliseValue value={referral.demographic ?? {}} />

      <h3>Meta Data</h3>
      <Detail convertValueCase value={referral.serviceMeta ?? {}} />

      <h3>Clinical</h3>
      <Detail value={clinical ?? {}} />

      <h3>Questionnaires</h3>
      {formattedQuestionnaires}
    </div>
  )
}
