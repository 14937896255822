import React from "react"
import { useLocation } from "react-router-dom"

export interface ITokenContext {
  token?: string
}

const TokenContext = React.createContext<ITokenContext | null>(null)

export function TokenProvider(props: React.PropsWithChildren) {
  const location = useLocation()

  const value = React.useMemo<ITokenContext>(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get("token") ?? undefined
    return { token }
  }, [location.search])

  return <TokenContext.Provider value={value} {...props} />
}

export function useTokenContext(): ITokenContext {
  const ctx = React.useContext(TokenContext)
  if (!ctx) {
    throw new Error("useTokenContext must be used within a TokenProvider")
  }
  return ctx
}
