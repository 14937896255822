import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ReferralList from "./screens/ReferralList/ReferralList"
import NotFound from "./screens/NotFound"
import { TokenProvider, useTokenContext } from "./contexts/TokenContext"
import NoToken from "./screens/NoToken"

function Navigator() {
  const { token } = useTokenContext()
  return (
    <Routes>
      {token ? (
        <Route path="/" element={<ReferralList />} />
      ) : (
        <Route path="/" element={<NoToken />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default function App() {
  return (
    <BrowserRouter>
      <TokenProvider>
        <Navigator />
      </TokenProvider>
    </BrowserRouter>
  )
}
