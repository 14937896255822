import * as React from "react"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import Search from "@mui/icons-material/Search"

interface IFilterInputProps {
  onChange: (text: string) => void
}

export default function FilterInput(props: IFilterInputProps) {
  const ref = React.useRef<boolean>(false)
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isTouched = ref.current
    ref.current = true
    if (event.key === "Enter") return props.onChange(event.currentTarget.value)
    if (isTouched && event.currentTarget.value === "") return props.onChange("")
  }
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <FormControl variant="outlined">
        <OutlinedInput
          id="filter-input"
          size="small"
          onKeyUp={onKeyUp}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  )
}
